import * as React from "react";
import { Page } from "../components/Page";

export default () => {
  return (
    <Page>
      <div
        style={{
          display: "flex",
          height: "100%",
          flex: "1 1 auto",
          flexDirection: "column",
          justifyContent: "center"
        }}
      >
        <h1 style={{ fontSize: 140 }}>404</h1>
      </div>
    </Page>
  );
};
